import { normalize, schema } from 'normalizr';

/**
 * @typedef {Object} Component -
 * @property {number} BlockId -
 * @property {number} BlockComponentId -
 * @property {number} ComponentTypeId -
 * @property {string} ComponentType -
 * @property {string} Title -
 * @property {string} Subheading -
 * @property {string} Paragraph -
 * @property {string} ImageUrl -
 * @property {string} ImageCaption -
 * @property {string} Url -
 * @property {string} AddButton -
 * @property {string} ButtonText -
 * @property {string} ButtonUrl -
 * @property {string} OpenInNewTab -
 * @property {number=} InfographicTypeId -
 * @property {string} InfographicType -
 * @property {number=} ResourceTypeId -
 * @property {string} ResourceType -
 * @property {number} SortOrder -
 * @property {Array} Contents -
 */

/**
 * @typedef {Object} Block
 * @property {number} CorePageId -
 * @property {number} BlockId -
 * @property {number} BlockTypeId -
 * @property {string} BlockType -
 * @property {string} BlockTitle -
 * @property {string} BlockLeadParagraph -
 * @property {string} BlockParagraph -
 * @property {string} BackgroundColor -
 * @property {number} SortOrder -
 * @property {Array<Component>} Components -
 */

/**
 * A Page return from /cms/designpage
 * @typedef {Object} PageData
 * @property {number} CorePageId -
 * @property {number} Cat1Id -
 * @property {string} MetaTitle -
 * @property {string} MetaDescription -
 * @property {array} CurrentSubjects -
 * @property {array} MemberAccessLevels -
 * @property {string} PageUrl -
 * @property {string} HeroParagraph -
 * @property {string} HeroHeadline -
 * @property {number=} HeroImageId -
 * @property {string} HeroImageAltText -
 * @property {string} PageAlias -
 * @property {string} Body - Markup
 * @property {string} StatusCode - "C"
 * @property {string} DateLastUpdated -
 * @property {Array<Block>} Blocks -
 */

function generateNormalizedData(data) {
  // prettier-ignore-start
  const content = new schema.Entity('Contents', {}, { idAttribute: 'ComponentContentId' });

  const component = new schema.Entity(
    'Components',
    { Contents: [content] },
    { idAttribute: 'BlockComponentId' }
  );

  const block = new schema.Entity(
    'Blocks',
    { Components: [component] },
    { idAttribute: 'BlockId' }
  );
  // prettier-ignore-end

  return normalize(data, {
    Blocks: [block],
  });
}

function formatNormalizedData(entities, result) {
  // These references are used below for sorting.
  const _entities = entities;
  const _result = result;
  const _blocks = _entities.Blocks;
  const _components = _entities.Components;
  const _contents = _entities.Contents;

  // Sort the Blocks by their SortOrder value
  _result.Blocks = _result.Blocks
    ? _result.Blocks.sort((a, b) => _blocks[a].SortOrder - _blocks[b].SortOrder)
    : [];

  // Sort the Blocks' Components by their SortOrder value
  _entities.Blocks = _blocks
    ? Object.keys(_blocks).reduce((acc, cur) => {
        const _block = _blocks[cur];
        _block.Components = _block.Components.sort((a, b) =>
          _components[a].SortOrder && _components[b].SortOrder
            ? _components[a].SortOrder - _components[b].SortOrder
            : _components[a].BlockComponentId - _components[b].BlockComponentId
        );
        acc[cur] = _block;
        return acc;
      }, {})
    : {};

  // Sort the Components' Contents by their SortOrder value
  _entities.Components = _components
    ? Object.keys(_components).reduce((acc, cur) => {
        const _component = _components[cur];
        _component.Contents = _component.Contents.sort(
          (a, b) => _contents[a].SortOrder - _contents[b].SortOrder
        );
        acc[cur] = _component;
        return acc;
      }, {})
    : {};

  return {
    ..._result,
    entities: _entities,
  };
}

/**
 * This uses normalizr to transform the data to a more friendly structure
 *
 * @param {PageData} data
 * @returns {{entities: {Components: {Object}, Blocks: {Object}}, result: {PageData}}} normalized data
 */
export function normalizeAndFormatPageData(data) {
  const normalizedData = generateNormalizedData(data);

  const { entities, result } = normalizedData;
  const normalizedPage = formatNormalizedData(entities, result);

  return normalizedPage;
}

/**  A modal for the cms returned field for design pages */
export default class DesignPage {
  /**
   * @param {object} cmsResponseData
   */
  constructor(cmsResponseData) {
    const pageData = normalizeAndFormatPageData(cmsResponseData);

    this.entities = pageData.entities;
    this.CorePageId = pageData.CorePageId;
    this.Cat1Id = pageData.Cat1Id;
    this.MetaTitle = pageData.MetaTitle;
    this.MetaDescription = pageData.MetaDescription;
    this.CurrentSubjects = pageData.CurrentSubjects;
    this.MemberAccessLevels = pageData.MemberAccessLevels;
    this.PageUrl = pageData.PageUrl;
    this.HeroHeadline = pageData.HeroHeadline;
    this.HeroParagraph = pageData.HeroParagraph;
    this.HeroImageId = pageData.HeroImageId;
    this.HeroImageAltText = pageData.HeroImageAltText;
    this.PageAlias = pageData.PageAlias;
    this.Body = pageData.Body;
    this.StatusCode = pageData.StatusCode;
    this.DateLastUpdated = pageData.DateLastUpdated;
    this.Blocks = pageData.Blocks;
  }

  /**
   * @param {number} id BlockId
   * @returns {object} The block object
   */
  getBlockDataById(id) {
    return this.entities.Blocks[`${id}`];
  }

  /**
   * @param {number} id BlockTypeId
   * @returns {object} The block object
   */
  getBlockDataByTypeId(id) {
    const blocks = Object.values(this.entities.Blocks);
    return blocks.find(({ BlockTypeId }) => BlockTypeId === id);
  }

  /**
   * @param {number} id BlockId
   * @returns {Array<Component>} The objects to which the `result`'s blocks' Components[] refer
   */
  getComponentsDataByBlockId(id) {
    const componentIds = this.entities.Blocks[`${id}`].Components;
    return componentIds.reduce((acc, cur) => {
      acc.push(this.entities.Components[cur]);
      return acc;
    }, []);
  }

  /**
   * @param {number} id BlockComponentId
   * @returns {object} The component object
   */
  getComponentDataById(id) {
    return this.entities.Components[`${id}`];
  }
}
