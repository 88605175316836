import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react';

import { bp, colors } from '@/styles';

const style = {
  section: halfPad => {
    const paddingValue = halfPad ? 20 : 40;
    return css`
      padding-top: ${paddingValue}px;
      padding-bottom: ${paddingValue}px;
      @media ${bp.md} {
        padding-top: ${paddingValue * 2}px;
        padding-bottom: ${paddingValue * 2}px;
      }
      @media print {
        padding-top: 28px !important;
        padding-bottom: 28px !important;
      }
      &:empty {
        padding: 0;
      }
    `;
  },
  theme: {
    white: css`
      background-color: ${colors.white};
    `,
    gray: css`
      background-color: ${colors.accentGrayUltralight};
    `,
    blue: css`
      color: ${colors.white};
      background-color: ${colors.secondaryBlue};
    `,
  },
};

const Section = ({ bgColor, children, halfPad, ...props }) => {
  return (
    <section css={[style.section(halfPad), style.theme[bgColor]]} {...props}>
      {children}
    </section>
  );
};

Section.propTypes = {
  bgColor: PropTypes.oneOf(['gray', 'white', 'blue']),
  children: PropTypes.node.isRequired,
  halfPad: PropTypes.bool,
};

Section.defaultProps = {
  bgColor: 'white',
  halfPad: false,
};

export default Section;
