import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Layout extends PureComponent {
  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
